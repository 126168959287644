<template>
  <v-select
    v-model="control.model[control.name]"
    v-bind="control"
    class="jexy-select"
    @change="$emit('change')"
  />
</template>

<script>

// Mixin
import Control from './Mixin'

export default {
  name: 'Select',
  mixins: [ Control ]
}
</script>

<style lang="scss">

.v-list-item__title {
  font-weight: bold;
}

</style>
